import $ from 'jquery';
import Swiper from 'swiper/bundle';

class SliderTextPicture{

	constructor(){
    if($('.js-slider-text-picture').length > 0){
      this.initSlider();
    }
	}

	initSlider(){
    let sliderList = $('.js-slider-text-picture .swiper-container');

		sliderList.each((index,element) => {

			let mySlider = new Swiper(element, {
				direction: 'horizontal',
				scrollbar: {
					hide: true,
					draggable: false
				},
				allowTouchMove: false,
        pagination: {
          el: '.js-slider-text-picture .swiper-pagination',
          type: 'bullets',
					clickable: true,
        },
        autoplay: {
         delay: 5000,
       },
       effect: 'fade',
       fadeEffect: {
         crossFade: true
       },
			});

    });
  }

}

export { SliderTextPicture };
