import $ from 'jquery';
import Swiper from 'swiper/bundle';

class SliderBoard{

	constructor(){
    if($('.js-board-slider').length > 0){
      this.initSlider();
    }
	}

	initSlider(){
    let sliderList = $('.js-board-slider .swiper-container');

		sliderList.each((index,element) => {

			let container = $(element).parents('.c-board');
			let pagination = $(container).find('.swiper-pagination').get(0);
			let arrow_next = $(container).find('.swiper-button-next').get(0);
			let arrow_prev = $(container).find('.swiper-button-prev').get(0);

			let mySlider = new Swiper(element, {
        navigation: {
          nextEl: arrow_next,
          prevEl: arrow_prev,
        },
				direction: 'horizontal',
				scrollbar: {
					hide: true,
					draggable: false
				},
				allowTouchMove: false,
        pagination: {
          el: pagination,
          type: 'bullets',
					clickable: true,
        },
			});

			mySlider.on('slideChange', function (swiper) {
				let newIndex = swiper.activeIndex;
				let selector = `.c-board__picture__item[data-number="${newIndex}"]`;
				$(container).find('.c-board__picture__item').removeClass('is-active');
				$(container).find(selector).addClass('is-active');
				let countIndex = newIndex + 1;
				$(container).find('.swiper-count span').text(countIndex);
			});

			$(container).find('.c-board__picture__item').on('click',(e) => {
				let index = $(e.currentTarget).attr('data-number');
				mySlider.slideTo(index,300);
			});

    });
  }

}

export { SliderBoard };
