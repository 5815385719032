import $ from 'jquery';
import baguetteBox from 'baguettebox.js';

export function baguette_box() {

  if( typeof baguetteBox !== 'undefined' ){
		baguetteBox.run(".js-gallery", {
      afterShow: () => {
        console.log( $('#baguetteBox-slider > div').length);
        if($('#baguetteBox-slider > div').length == 1){
          $('#previous-button,#next-button').css('display','none');
        }
        else{
          $('#previous-button,#next-button').css('display','block');
        }
      }
    });
	}

}
