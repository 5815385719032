import $ from 'jquery';

class MenuMobile{

	constructor(){
    this.init();
	}

	init(){
    $('.js-toggle-menu-mobile').on('click',(e) => {
      $('.menu-mobile').toggleClass('is-open');
			$('html').toggleClass('menu-is-open');
    });
  }

}

export { MenuMobile };
