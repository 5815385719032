import Cookies from "js-cookie";

var CONFIG = window.CONFIG || {};

/*
***
	Callback du chargement du tracker
	/* Track page view
***/

class RiriCookie {
	constructor() {
		/* Dom elements */
		this.dom = {
			cookiebanner: document.querySelector(".cookiebanner"),
			closeAccept: document.querySelectorAll(
				".js-cookiebanner-close-accept"
			),
			closeReject: document.querySelectorAll(
				".js-cookiebanner-close-reject"
			)
		};

		if (!this.dom.cookiebanner) {
			console.warn(" !! Tu as oublié le code HTML de la bannière !!");
			return;
		}

		this.isOn = false;

		// this.onScrollAccept = this.onScrollAccept.bind(this);

		this.init();
	}

	init() {
		const cookieStatus = Cookies.get("cookiebanner");

		// If we don't have a cookie
		if (cookieStatus === undefined) {
			// We show the banner
			this.showBanner();

			// Cookie is present and already accepted
		} else if (cookieStatus === "accepted") {
			this.initAnalytics();
		}
	}

	//
	showBanner() {
		this.dom.cookiebanner.classList.add("is-active");

		// Close Accept cookie
		this.dom.closeAccept.forEach(_el =>
			_el.addEventListener(
				"click",
				() => {
					this.closeBanner();
					this.acceptCookie();
				},
				false
			)
		);

		// Close Reject cookie
		this.dom.closeReject.forEach(_el =>
			_el.addEventListener(
				"click",
				e => {
					this.closeBanner();
					this.rejectCookie();
				},
				false
			)
		);

		// L'acceptation se fait si l'utilisateur navigue sur le site
		document
			.querySelectorAll("a:not(.js-cookiebanner-close-reject)")
			.forEach(_el => {
				_el.addEventListener(
					"click",
					() => {
						this.closeBanner();
						this.acceptCookie();
					},
					false
				);
			});

		// L'acceptation se fait si l'utilisateur scrolle sur le site (pas indiqué à la CNIL)
		// On doit passer par une fonction directe pour enlever l'événement dès qu'il est déclenché au moins une fois.
		// Délai de 1 seconde sinon le scroll est déclenché à l'ouverture de la page
		// Il faudrait améliorer ça. (ou enlever cet event de scroll)
		// window.setTimeout(() => {
		// 	document.addEventListener("scroll", this.onScrollAccept, false);
		// }, 1000);
	}

	// onScrollAccept() {

	// 	document.removeEventListener("scroll", this.onScrollAccept);

	// 	this.closeBanner();
	// 	this.acceptCookie();
	// }

	closeBanner() {
		this.dom.cookiebanner.classList.remove("is-active");
	}

	rejectCookie() {
		// console.log("reject");
		// Put cookie and Init tag
		Cookies.set("cookiebanner", "rejected", {
			expires: 365
		});
	}

	acceptCookie() {
		// Put cookie and Init tag
		Cookies.set("cookiebanner", "accepted", {
			expires: 365
		});

		this.initAnalytics();
	}

	/* Insert here your tag */
	initAnalytics() {
		(function (i, s, o, g, r, a, m) {
			i["GoogleAnalyticsObject"] = r;
			(i[r] =
				i[r] ||
				function () {
					(i[r].q = i[r].q || []).push(arguments);
				}),
				(i[r].l = 1 * new Date());
			(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
			a.async = 1;
			a.src = g;
			m.parentNode.insertBefore(a, m);
		})(
			window,
			document,
			"script",
			"https://www.google-analytics.com/analytics.js",
			"ga"
		);

		ga("create", "UA-209920327-1", "auto");
		ga("send", "pageview");

		// On peut créer autant de tags qu'on veut, ex: pour tester sa bannière
		// ga("create", "UA-XX-XX-XX", "auto", { name: "pg" });
		// ga("pg.send", "pageview");
	}
}

export default RiriCookie;
