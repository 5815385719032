class Modal {
	constructor() {
		this.dom = {
			trigger: document.querySelectorAll(".js-modal-trigger"),
			modals: document.querySelectorAll(".js-modal"),
			close: document.querySelectorAll(".js-modal-close"),
			overlay: document.querySelector(".js-modal-overlay"),
			html: document.querySelector("html"),
		};

		if (!this.dom.trigger) return;

		this.openedModal = null;

		this.init();
		this.onCloseEvents();
	}

	init() {
		this.dom.trigger.forEach((_el) =>
			_el.addEventListener(
				"click",
				(_e) => {
					this.onOpen(_e);
				},
				false
			)
		);
	}

	onOpen(_e) {
		const target = _e.currentTarget;
		const name = target.getAttribute("data-modal");
		const modal = document.querySelector(`.js-modal[data-modal='${name}']`);

		this.openedModal = modal;

		modal.classList.add("is-active");
		this.dom.overlay.classList.add("is-active");

		this.dom.html.classList.add("is-locked");
	}

	onCloseEvents() {
		// Close button
		this.dom.close.forEach((_el) =>
			_el.addEventListener("click", (_e) => {
				this.onClose();
			})
		);

		// Close on Overlay click
		this.dom.overlay.addEventListener("click", (_e) => {
			this.onClose();
		});

		// Close on Esc Key press
		document.addEventListener("keydown", (evt) => {
			evt = evt || window.event;
			var isEscape = false;
			if ("key" in evt) {
				isEscape = evt.key === "Escape" || evt.key === "Esc";
			} else {
				isEscape = evt.keyCode === 27;
			}
			if (isEscape) {
				this.onClose();
			}
		});
	}

	onClose() {
		if (!this.openedModal) return;

		this.openedModal.classList.remove("is-active");
		this.dom.overlay.classList.remove("is-active");
		this.dom.html.classList.remove("is-locked");
	}
}

export { Modal };
