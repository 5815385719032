import $ from 'jquery';

class Tableau{

	constructor(){
    if($('.js-tableau-mobile').length > 0){
      this.init();
    }
	}

	init(){
    $('.js-tableau-mobile .c-tableau__mobile__header').on('click',(e) => {
      $(e.currentTarget).parents('.c-tableau__mobile__item').toggleClass('is-opened');
    });
  }

}

export { Tableau };
