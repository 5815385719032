import { Functions } from "../classes/Functions";
import SmoothScroll from "smooth-scroll";
import $ from 'jquery';

export function smoothScroll() {
	const FUNCTIONS = new Functions();

	if (FUNCTIONS.detectIE() == false) {
		const scroll = new SmoothScroll('a[href*="#"]', {
			ignore: ".c-gallery__item", // Selector for links to ignore (must be a valid CSS selector)
			//header: "header.header",
			speed: 300,
			updateURL: false,
			offset: 30,
		});
	}

}
