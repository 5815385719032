import $ from 'jquery';

class Formulaire {

	constructor() {
		if ($('.custom-formidable').length > 0) {
			this.checkboxFormidable();
		}
		if ($('.js-tableau-form-autocomplete').length > 0) {
			this.autocompleteTableauForms();
		}
	}

	checkboxFormidable() {
		let element = '<div class="custobox"></div>';
		$('.custom-formidable .frm_checkbox label').append(element);
	}

	autocompleteTableauForms() {
		$('.js-tableau-form-autocomplete').on('click', (e) => {
			let tab_container = $(e.currentTarget).parents('.c-tableau');
			let row_container = $(e.currentTarget).parents('.row-container');
			let tab_type = $(e.currentTarget).attr('data-tab-type');
			let modal_name = $(e.currentTarget).attr('data-modal');
			let modal_selector = '.modal[data-modal="' + modal_name + '"]';
			let modal_cible = tab_container.find(modal_selector);

			if (tab_type == 'room') {
				let num_lot = row_container.find('.data-numero-lot').text();
				let surface = row_container.find('.data-surface').text();
				let chambre = row_container.find('.data-chambre').text();
				$(modal_cible).find('input[id^="field_num-lot"]').val(num_lot);
				$(modal_cible).find('input[id^="field_surface"]').val(surface);
				$(modal_cible).find('input[id^="field_chambre"]').val(chambre);
			}
			else if (tab_type == 'office') {
				let type = row_container.find('.data-type').text();
				let surface = row_container.find('.data-surface').text();
				let etage = row_container.find('.data-etage').text();
				$(modal_cible).find('input[id^="field_type"]').val(type);
				$(modal_cible).find('input[id^="field_surface-bureau"]').val(surface);
				$(modal_cible).find('input[id^="field_etage"]').val(etage);
			}
			else if (tab_type == 'shops') {
				let type = row_container.find('.data-type').text();
				let surface = row_container.find('.data-surface').text();
				let etage = row_container.find('.data-etage').text();
				$(modal_cible).find('input[id^="field_type"]').val(type);
				$(modal_cible).find('input[id^="field_surface-bureau"]').val(surface);
				$(modal_cible).find('input[id^="field_etage"]').val(etage);
			}
			else if (tab_type == 'car_park') {
				let etage = row_container.find('.data-etage').text();
				let archive = row_container.find('.data-archive').text();
				let parking = row_container.find('.data-parking').text();
				$(modal_cible).find('input[id^="field_etage-parking"]').val(etage);
				$(modal_cible).find('input[id^="field_archive"]').val(archive);
				$(modal_cible).find('input[id^="field_parking"]').val(parking);
			}

		});
	}

}

export { Formulaire };
