import { Functions } from "./classes/Functions";
import { Formulaire } from "./classes/Formulaire";
import { SliderBoard } from "./classes/SliderBoard";
import { Modal } from "./classes/Modal";
import { SliderTextPicture } from "./classes/SliderTextPicture";
import { MenuMobile } from "./classes/MenuMobile";
import { Tableau } from "./classes/Tableau";
// import { PictureZoom } from "./classes/PictureZoom";
import { smoothScroll } from "./components/smoothScroll";
import { baguette_box } from "./components/baguetteBox";
import RiriCookie from "./classes/riri-cookie";

document.addEventListener("DOMContentLoaded", (event) => {

	const FUNCTIONS = new Functions();
	const formulaire = new Formulaire();
	const sliderBoard = new SliderBoard();
	const modal = new Modal();
	const sliderTextPicture = new SliderTextPicture();
	const menuMobile = new MenuMobile();
	const tableau = new Tableau();

	smoothScroll();
	baguette_box();

	const ririCookie = new RiriCookie();

	//const pictureZoom = new PictureZoom();

});
